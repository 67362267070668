<template>
  
    <div class="informationen narrow">
        <h1 class="text-center">Geschichte</h1>
        <p class="lead">Wie die Spielgruppe „Gigampfi“ entstand</p>

        <p>
        1977 wurde unser Gigampfi ins Leben gerufen.
        Drei Frauen (Peyer, Brühlmann, von Wartburg) aus Hochdorf, die Kinder im Spielgruppenalter hatten, suchten einen passenden Raum, zwei ausgebildete Kindergärtnerinnen (Margrith Gloor / Sonja Schurtenberger) und noch ein paar andere Kinder. Es ging nicht lange, verfügten sie über eine grosse Kinderschar, die im Pfarreizentrum betreut wurden. Schon bald konnten die zwei Frauen diese Arbeit nicht mehr alleine meistern und fanden in Lisbeth Mühlebach und Beatrice Affentranger zwei weitere Leiterinnen.
        </p><p>
        Nachdem die vier ausgebildeten Kindergärtnerinnen ihre langjährige Arbeit kündeten, gingen sie auf die Suche nach neuen Leiterinnen. Da dies nicht so einfach war, jemanden zu finden, stellte man die Bedingung der Gemeinde, dass nur ausgebildete Kindergärtnerinnen das „Gigampfi“ leiten dürfen, in den Hintergrund. Eine ausgebildete Kindergärtnerin, eine Heimerzieherin und eine kaufmännische Angestellte wurden als Leiterinnen angefragt. Ein Grundkurs auf dem Schwarzenberg war Voraussetzung, um in den Beruf als Spielgruppenleiterin einzusteigen.
        </p><p>
        Da nun der Gigampfiraum zu klein wurde - resp. die Kinderzahlen gestiegen waren (ca. 120 Kinder) - beschloss man, sich nach einer zweiten Räumlichkeit umzusehen. Das war nicht so einfach. Rita von Wartburg (ehemalige Gemeinderätin) war uns gut gesinnt und fand im Schalander einen geeigneten Platz. Nach ca. zwei Jahren wurde leider der Schalander abgebrochen und wiederum musste eine neue Örtlichkeit gefunden werden. Die Bankstrasse 9 bot genau das, was wir brauchten. Zehn Jahre war dies unser Zuhause. Lea Bischof, unsere Gemeinderätin, fand im 2006 für uns die neuen passenden Räumlichkeiten, nämlich den feudalen Kindergarten Braui, den wir aber leider bereits im Juli 2008 wieder verlassen mussten. Lea Bischof fand aber bald ein neues geeignetes Plätzchen für unsere Kinder im Schulhaus Zentral.
        </p><p>
        In all den Jahren hat sich die Spielgruppe weiterentwickelt. Im 1997 boten wir auf vielseitigen Wunsch der Bevölkerung die Spielgruppe für 4-jährige Kinder an, und das 1 mal 2 Stunden pro Woche. Die Kinderzahl pro Gruppe beschränkte sich auf max. 10 Kinder. Die 5-jährigen Kinder (pro Gruppe max. 15 Kinder) besuchten das Gigampfi 2 mal pro Woche.
        </p><p>
        Im 2005 schaukelte die Spielgruppe „Gigampfi“ mit neuem Schwung. Damit wir den allgemeinen Veränderungen im Schulwesen (frühere Einschulung/Blockzeiten) und somit auch in der Spielgruppe Schritt halten konnten, musste das Gigampfi eine Anpassung vornehmen. Es wurden erstmals altersgemischte Gruppen angeboten. 12 Kinder im Alter von 3 Jahren bis zum Kindergarteneintritt wurden nun gemeinsam von der Spielgruppenleiterin begleitet und gefördert. Auch die Nachmittagslektion wurde jeweils ab Januar um eine halbe Stunde verlängert. Was wir nach 2-jähriger Erfahrung beobachten konnten, wirkte sich diese Veränderung positiv auf die soziale Entwicklung aus. Allerdings wurde die Warteliste der angemeldeten Kinder immer länger und wir entschlossen uns, für die „kleinen“ Dreijährigen eine separate Gruppe zu machen. So entstanden die „Mini`s“, die jeweils 1 mal 2.5 Stunden pro Woche das Gigampfi besuchen.</p>
    </div>

</template>

<script>
export default {
    name: 'Informationen'
}
</script>
